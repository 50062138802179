import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Query } from '@shared/interfaces/utilities/query-parameter.interface';
import { ShootStudio } from '@shared/interfaces';
import { ApiDataService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class ShootStudioService {

  public readonly SHOOT_STUDIO_BASE = '/studios';

  constructor(private apiDataService: ApiDataService) {}

  public getShootStudio(id: number | string) {
    return this.apiDataService.get(`${this.SHOOT_STUDIO_BASE}/${id}`);
  }

  public getShootStudios(params?: Query[]) {
    const query = [
      {
        key: 'include',
        value:'reservations.booker,shoots.curator,shoots.responsible'
      },
      ...(params || [])
    ];

    return this.apiDataService.get(this.SHOOT_STUDIO_BASE, query)
  }

  public reserveShootStudio(studioId: number, blockData: any): Observable<ShootStudio> {
    return this.apiDataService.post(`${this.SHOOT_STUDIO_BASE}/${studioId}/reservations`, blockData)
  }

  public updateShootStudioReservation(
    studioId: number,
    reservationId: number,
    blockData: any
  ): Observable<ShootStudio> {
    return this.apiDataService.patch(`${this.SHOOT_STUDIO_BASE}/${studioId}/reservations/${reservationId}`, blockData);
  }

  public removeShootStudioReservation(studioId: number, bookingId: any): Observable<undefined> {
    return this.apiDataService.delete(`${this.SHOOT_STUDIO_BASE}/${studioId}/reservations/${bookingId}`);
  }

  public createShootStudio(data: any) {
    return this.apiDataService.post(this.SHOOT_STUDIO_BASE, data);
  }

  public isShootStudioAvailable(data: any) {
    return this.apiDataService.post(`${this.SHOOT_STUDIO_BASE}/is_available`, data);
  }


  public updateShootStudio(id: number, data: any) {
    return this.apiDataService.patch(`${this.SHOOT_STUDIO_BASE}/${id}`, data);
  }

}
