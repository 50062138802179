import { Injectable } from '@angular/core';

import { ApiDataService } from '@shared/services';
import { Observable } from 'rxjs';
import { Call } from '@calls/calls.interface';

@Injectable({
  providedIn: 'root'
})
export class CallsService {

  public readonly CALLS_BASE = '/calls';

  constructor(private apiDataService: ApiDataService) {
  }

  public makeCall(data: any) {
    return this.apiDataService.post('/calls', data);
  }

  public endCall(id: number, data: any) {
    return this.apiDataService.patch(`/calls/${id}`, data);
  }

  public getCall(id: number): Observable<Call> {
    return this.apiDataService.get(`${this.CALLS_BASE}/${id}`);
  }
}
