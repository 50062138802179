import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Shoot } from 'src/app/shared/interfaces/shoots/shoot';
import { PartnerService } from '@partners/partner.service'

@Injectable({ providedIn: 'root' })
export class PartnerResolver implements Resolve<Shoot> {

  constructor(private service: PartnerService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Shoot> {
    const {id, partnerId} = route.params;

    return this.service.getPartnerById(id || partnerId);
  }
}
