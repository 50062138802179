export enum PartnerStatus {
  TO_REVIEW     = 'to_review',
  NOT_CONFIRMED = 'not_confirmed',
  COLD          = 'cold',
  POTENTIAL     = 'potential',
  REJECTED      = 'rejected',
  CURRENT       = 'current',
  SUSPENDED     = 'suspended'
}

export enum PartnerStatusLabel {
  TO_REVIEW      = 'Требует проверки',
  NOT_CONFIRMED  = 'Не подтвержден',
  COLD           = 'Холодный',
  POTENTIAL      = 'Потенциальный',
  REJECTED       = 'Отказ',
  CURRENT        = 'Текущий',
  SUSPENDED      = 'Приостановлен'
}
