import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiDataService } from '@shared/services'
import { Lead } from '@shared/interfaces/lead'

@Injectable({
  providedIn: 'root'
})
export class LeadParticipantService {

  public readonly BASE_URL: string = '/leads/participants';

  constructor(private apiDataService: ApiDataService) {}

  public getLeadById(id: string): Observable<Lead> {
    return this.apiDataService.get(`${this.BASE_URL}/${id}`)
  }

  public createLead(data: any): Observable<Lead> {
    return this.apiDataService.post(`${this.BASE_URL}`, data);
  }

  public updateLead(id: string, data: any): Observable<Lead> {
    return this.apiDataService.post(`${this.BASE_URL}/${id}`, data);
  }

}
