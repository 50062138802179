import { ListFilterEntity, Query } from 'src/app/shared/interfaces'
import { FilterType } from 'src/app/shared/enums/utils'
import { APIQueryBuilder } from '@shared/classes/api-query-builder';

export class QueryHelper {

    public parseFilterDataToQueryObject(data: ListFilterEntity[]): Query[] {
        return data.reduce((acc, item) => {
          switch(item.type) {
            case FilterType.MULTISELECT:
                return [...acc, ...this.parseMultiSelectFilter(item)]
            case FilterType.AUTOCOMPLETE:
                return [...acc, ...this.parseAutoCompleteFilter(item)]
            case FilterType.RADIO:
                return [...acc, ...this.parseRadioFilter(item)]
            case FilterType.DATERANGE:
                return [...acc, ...this.parseDateRangeFilter(item)]
            case FilterType.TEXT:
                return [...acc, ...this.parseTextFilter(item)]
            default:
                return acc
          }
        }, [])
    }


    private parseAutoCompleteFilter(filter: ListFilterEntity): Query[] {
        const isSomeSelected = filter.items && filter.items.length > 0;

        return isSomeSelected ?
            [{
                key: 'filter',
                oper: `[${filter.value}]=`,
                value: this.getSelectedValuesString(filter.items)
            }]:
            [];
    }

    private getSelectedValuesString(items): string {
        return items.map(item => item.value).join(',');
    }

    private parseMultiSelectFilter(filter: ListFilterEntity): Query[] {
        const isSomeItemsSelected = filter.items &&
            filter.items.filter(item => item.selected).length > 0;

        if(isSomeItemsSelected) {
            const selectedItems = filter.items.filter(item => item.selected);

            return [{
                key: 'filter',
                oper: `[${filter.value}]=`,
                value: this.getSelectedValuesString(selectedItems)
            }];

        } else {
            return [];
        }
    }

    private parseRadioFilter(filter: ListFilterEntity): Query[] {
        return filter.items.filter(item => item.selected).map(item => {
            return {
                key: filter.value,
                oper: '=',
                value: item.value
            }
        })
    }

    private parseTextFilter(filter: ListFilterEntity): Query[] {
        return [{
            key: 'filter',
            oper: `[${filter.value}]=`,
            value: filter.input
        }]
    }

    private parseDateRangeFilter(filter: ListFilterEntity): Query[] {
        if(!filter.to || !filter.from) {
            return [];
        }

        return [
            {
                key: filter.value,
                oper: '>=',
                value: filter.from
            },
            {
                key: filter.value,
                oper: '<=',
                value: filter.to
            }
        ];
    }

    public decorateQueryObject(list) {
        return list.map(item => {
            item.selected = false
            return item
        })
    }

    public applyToQueryBuilder(data: ListFilterEntity[], builder: APIQueryBuilder): APIQueryBuilder {
      return data.reduce((acc, item) => {
        switch(item.type) {
          case FilterType.MULTISELECT:
            const isSomeItemsSelected = item.items &&
              item.items.filter(fItem => fItem.selected).length > 0;

            if(!isSomeItemsSelected) return acc.removeFilter(item.value);
            const selectedItems = item.items.filter(f => f.selected);

            return acc.addFilter(item.value, this.getSelectedValuesString(selectedItems));
          case FilterType.AUTOCOMPLETE:
            const isSomeSelected = item.items && item.items.length > 0;

            return isSomeSelected ? acc.addFilter(item.value, this.getSelectedValuesString(item.items)) : acc;
          // case FilterType.RADIO:
          //   return [...acc, ...this.parseRadioFilter(item)]
          // case FilterType.DATERANGE:
          //   return [...acc, ...this.parseDateRangeFilter(item)]
          case FilterType.TEXT:
            return (item.input) ?
              acc.addFilter(item.value, item.input):
              acc.removeFilter(item.value);
          default:
            return acc
        }
      }, builder)
    }
}
