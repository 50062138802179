import { DataTransformHelper } from '@shared/helpers/data-transform.helper';
import { RowConfig } from '@shared/interfaces';

export const TABLE_CELL_VALUE_MAPPING: RowConfig[] = [
  {
    key: 'updated_at',
    getValue: value => DataTransformHelper.getDateFromDateTime(value),
    allowSort: true
  },
  {
    key: 'created_at',
    getValue: value => DataTransformHelper.getDateFromDateTime(value),
    allowSort: true
  },
  {
    key: 'title',
    getValue: value => value,
    allowSort: true
  }
];
