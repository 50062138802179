export class AppearanceTypeValidationMessage {
    public static title = [
        { type: 'required', message: 'Поле \'Тип внешности\' обязательно для заполнения' },
        { type: 'pattern', message: 'Поле \'Тип внешности\' должно быть в текстовом формате' }
    ]
}

export class ActivityTypeValidationMessage {
    public static title = [
        { type: 'required', message: 'Поле \'Тип деятельности\' обязательно для заполнения' },
        { type: 'pattern', message: 'Поле \'Тип деятельности\' должно быть в текстовом формате' }
    ]
}

export class DepartmentValidationMessage {
    public static title = [
        { type: 'required', message: 'Поле \'Отдел\' обязательно для заполнения' },
        { type: 'pattern', message: 'Поле \'Отдел\' должно быть в текстовом формате' }
    ]
}