import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiDataService } from '@shared/services';
import { Partner } from '@shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private readonly BASE_URL: string = '/partners';

  constructor(private apiDataService: ApiDataService) {}

  public createPartner(body: any) {
    return this.apiDataService.post(this.BASE_URL, body)
  }

  public getPartnerById(id: number) {
    return this.apiDataService.get(`${this.BASE_URL}/${id}`)
  }

  public confirmPartner(id: number, taskId: number): Observable<Partner> {
    return this.apiDataService.patch(`${this.BASE_URL}/${id}/confirm`, {meta_data: {task_id: taskId}});
  }

  public rejectPartner(id: number, taskId: number, comment: string): Observable<Partner> {
    const body = {
      meta_data: {task_id: taskId},
      comment
    }

    return this.apiDataService.patch(`${this.BASE_URL}/${id}/reject`,body);
  }

}
