export enum GeneralCallResult {
  Clarifying = 'clarifying',
  Consent = 'consent',
  Potential= 'potential',
  Rejected = 'rejected',
  OnResults = 'on_results',
  NoAnswer = 'no_answer'
}

export enum GeneralCallResultLabel {
  Clarifying = 'Уточнение',
  Consent = 'Согласие',
  Potential= 'Потенциальный',
  Rejected = 'Отказ',
  OnResults = 'По результатам',
  NoAnswer = 'Недозвон'
}
